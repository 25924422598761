* {
  box-sizing: border-box;
}

@media only screen and (max-width: 959px) {
  .body {
    display: grid;
  }
}

@media only screen and (max-width: 500px) {
  #content-wrap > div:nth-child(2) > div > div > div > div.yAxis-label-div {
    visibility: hidden;
  }

  #content-wrap > div:nth-child(2) > div > div > div > div:nth-child(2) > div.wrapper > div.leftBar {
    visibility: hidden;
  }

  #content-wrap > div:nth-child(2) > div > div > div > div:nth-child(2) > div.wrapper > div.footer {
    display: none;
  }

  #content-wrap > div:nth-child(2) > div > div > div > div:nth-child(2) > center > p {
    display: none;
  }
}

code {
  font-size: 87.5%;
  color: #007833 !important;
  word-break: break-word;
}

.show-hide-warnings {
  font-size: 9px !important;
  margin: 2px !important;
  background-color: transparent !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.toast {
  font-size: 0.725rem !important;
  text-align: left;
}

.exterior-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.wrapper {
  margin-top: -15px;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
  display: grid;
  grid-gap: 10px;
  grid-template-areas:
    "header header header"
    "leftBar table rightBar"
    ". footer .";
}

.loading-spinner {
  height: 75vh;
  display: grid;
}
.spinner-border {
  margin: auto;
  color: #00cc99;
}

/*System Map Styles*/
.table {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(36, auto) 1fr;
}

.ascent-table {
  display: grid;
  grid-gap: 3px;
  grid-template-rows: repeat(18, auto) 1fr;
  width: 80vw;
  justify-content: center;
}

.element-summit {
  cursor: pointer;
  width: 2vw;
  height: 0.3vw;
  border-collapse: collapse;
  overflow: hidden;
  background: #ddd;
}

.element-ascent {
  cursor: pointer;
  width: 20vw;
  height: 1.6vw;
  border-collapse: collapse;
  overflow: hidden;
  background: #ddd;
}

.header {
  grid-area: header;
  padding: 10px;
  align-items: end;
  color: black;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.leftBar {
  grid-area: leftBar;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-items: end;
}

.rightBar {
  grid-area: rightBar;
}

.footer {
  display: grid;
  grid-area: footer;
  grid-gap: 1px;
  grid-template-columns: repeat(36, auto) 1fr;
  grid-template-rows: 50px;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 5.5rem; /* Footer height */
}

.activated.hover {
  background: #00cc99 !important;
}

.activated {
  background: #007833;
}

.unused.hover {
  background: #ed4e4e !important;
}

.unused {
  background: #b30000;
}

.disabled {
  background: white;
  cursor: default;
}

.hover.disabled {
  background: white !important;
}

.yAxis {
  color: #585858;
  font-size: 12px;
}

.yAxis-label {
  transform: rotate(-90deg);
  transform-origin: left;
  color: #585858;
  font-size: 12px;
  width: 2vw;
  height: 1vw;
  margin-bottom: 130px;
}

.yAxis-label-div {
  padding-right: 20px;
}

.xAxis {
  text-align: center;
  color: #585858;
  font-size: 12px;
  width: 2vw;
  height: 1vw;
  border-collapse: collapse;
}

.metadata-item {
  font-size: smaller;
}

.tooltip-top > .tooltip-inner {
  color: #fff;
}

.tooltip-arrow {
  display: none !important;
}

[class*="tooltip"] {
  pointer-events: none;
}

/* Navbar Styles */
.dropdown-menu {
  max-height: 80vh;
  overflow: scroll;
}

.dropdown-menu .dropdown-item {
  color: #585858;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
}

.dropdown-menu .dropdown-item:hover {
  color: #00cc99;
}

.dropdown-menu .dropdown-item:active {
  background-color: transparent;
}

.modal {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
}

.modal-body {
  color: #585858;
  font-size: 14px;
}

.modal-body > span {
  color: #007833;
}

.modal-50w {
  width: 50% !important;
  max-width: none !important;
  padding: 15px;
}

.system-map-footer {
  grid-template-columns: 1fr 1fr;
  font-size: 13px;
  color: white;
  background-color: #343a40;
  overflow: visible;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
}

/* Node Map Styles */
.node {
  display: grid;
  grid-template-areas:
    "sock0 bus sock1"
    "nvme nvme nvme";
  grid-template-columns: 1fr 60px 1fr;
  padding-bottom: 15px;
}

.nvme-disabled {
  opacity: 0.3;
}

.nvme-enabled {
  opacity: 1;
}

.nvme-text {
  position: absolute;
  font-size: 13px;
}

.nvme {
  grid-area: nvme;
  display: grid;
  border-style: solid;
  border-color: darkslategrey;
  border-width: 4px;
  border-radius: 5px;
  background-color: #dddbd8;
  margin-top: 5px;
  padding: 5px;
  width: 960px;
  justify-self: center;
}

.socket0 {
  grid-area: sock0;
  display: grid;
  grid-template-areas:
    "socket-label-left gpu0"
    "memory0 cpu0";
  border-style: solid;
  border-color: darkslategrey;
  border-width: 4px;
  border-radius: 5px;
  background-color: #b0b0b0;
  padding: 15px;
  justify-self: flex-end;
}

.socket1 {
  grid-area: sock1;
  display: grid;
  grid-template-areas:
    "gpu1 socket-label-right"
    "cpu1 memory1";
  border-style: solid;
  border-color: darkslategrey;
  border-width: 4px;
  border-radius: 5px;
  background-color: #b0b0b0;
  padding: 15px;
  justify-self: flex-start;
}

.CPU {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #1f4e79;
  border-radius: 2px;
  box-shadow: 0 0 0 2px #1f4e79;
}

/* Component: HardwareThread */
.CPU > div {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
  font-size: small;
  color: white;
  background-color: rgb(168, 168, 168, 0.4);
  border-style: solid;
  border-color: #1f4e79;
  border-width: 2px;
  border-radius: 5px;
  padding-top: 3px;
}

.cpuZone0 {
  grid-area: cpu0;
  display: grid;
  grid-template-rows: repeat(7, 70px);
  grid-auto-columns: 100px;
  grid-gap: 7px;
  grid-auto-flow: column;
  border-width: 2px;
  justify-self: end;
}

.cpuZone1 {
  grid-area: cpu1;
  display: grid;
  grid-template-rows: repeat(7, 70px);
  grid-auto-columns: 100px;
  grid-gap: 7px;
  grid-auto-flow: column;
  border-width: 2px;
}

.nodeView {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
  margin-top: 10px;
  text-align: center;
  justify-self: center;
}

.nodeView-Alert {
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  z-index: -1;
}

.socket-label-left {
  grid-area: socket-label-left;
  justify-self: left;
  font-size: 15px;
}

.socket-label-right {
  grid-area: socket-label-right;
  justify-self: right;
  font-size: 15px;
}

.gpuZone0 {
  grid-area: gpu0;
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-template-rows: 100px;
  grid-gap: 7px;
  padding-bottom: 10px;
}

.gpuZone1 {
  grid-area: gpu1;
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-template-rows: 100px;
  grid-gap: 7px;
  padding-bottom: 10px;
}

.gpu {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
  font-size: 16pt;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #a57676;
  box-shadow: 0 0 0 2px #a57676;
  border-radius: 2px;
}

.memory0 {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
  display: flex;
  grid-area: memory0;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: darkslategrey;
  border-width: 4px;
  border-radius: 5px;
  margin-right: 15px;
  padding-right: 6px;
  padding-left: 6px;
  background-color: #dddbd8;
}

.memory1 {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
  display: flex;
  grid-area: memory1;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: darkslategrey;
  border-width: 4px;
  border-radius: 5px;
  margin-left: 15px;
  padding-right: 6px;
  padding-left: 6px;
  background-color: #dddbd8;
}

.transfer {
  display: grid;
  grid-template-columns: none;
  background: grey;
  margin-left: -6px;
  width: 120%;
}

/*Navbar Styles*/
.Ttext {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 15px;
  color: #212529;
}

.Ticon {
  font-size: 30px;
  color: #212529;
  margin-top: -10px;
}

#content-wrap > div.navigationBar > nav > a:nth-child(2) > span {
  font-size: medium !important;
}

#responsive-navbar-nav > div.mr-auto.navbar-nav {
  font-size: smaller !important;
}

#responsive-navbar-nav > div:nth-child(2) > div > a {
  font-size: smaller !important;
}

#active-nodes-dropdown > div > input {
  font-size: smaller !important;
}

#active-nodes-dropdown > div > ul > a {
  font-size: smaller !important;
}

.footer-right {
  float: right;
  padding: 10px;
}

.footer-left {
  font-size: xx-small;
  text-align: left;
  float: left;
  padding: 10px;
}

.footer-center {
  display: inline-block;
  margin: 0 auto;
}

.navbar {
  background-color: #e2e3e4 !important;
  font-family: "Oswald", sans-serif !important;
}

.site-footer {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #e2e3e4 !important;
  text-align: center;
}

.navbar-brand {
  color: #007833 !important;
  font-size: 24px;
}

.fade:not(.show) {
  opacity: 0;
  display: none;
}

.small-text {
  font-size: small;
}

.map-legend-red {
  font-size: 12px;
  color: #b30000;
}

.map-legend-green {
  font-size: 12px;
  color: #007833;
}

.map-legend-grey {
  color: #ddd;
}

.map-legend-white {
  color: white;
  border: 1px black solid;
}

.map-legend-size {
  font-size: 12px;
}

.map-legend-left {
  width: 200px;
  float: left;
}

.map-legend-hostname-scheme {
  padding-top: 10px;
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 10px 5px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  word-break: normal;
  border-color: black;
}
.tg th {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  padding: 10px 5px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  word-break: normal;
  border-color: black;
}
.tg .tg-cly1 {
  text-align: left;
  vertical-align: middle;
}
.tg .tg-baqh {
  text-align: center;
  vertical-align: top;
}
.tg .tg-wa1i {
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-amwm {
  font-weight: bold;
  text-align: center;
  vertical-align: top;
}
.tg .tg-nrix {
  text-align: center;
  vertical-align: middle;
}
.tg .tg-0lax {
  text-align: left;
  vertical-align: top;
}
